<template>
  <!-- id : contents : (S) -->
  <div id='contents'>
    <div class="sub-visual-wrap st-community">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none;">홈</li>
          <li>{{ menuName }}</li>
          <li>메인</li>
        </ol>
      </div>
    </div>

    <!-- class : content-wrap : (S) -->
    <section class="content-wrap">
      <div class="inner-base-box">
        <div class="tab-menu-box">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div>
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="sub-tab-menu-box st-community mb40">
          <a href="javascript:;" v-for="(tab) in tabs" @click="onClickTab(tab)" :key="tab.boardId">{{ tab.menuName }}</a>
        </div>
        </div>
      </div>

      <!-- class : main-wrap (S) -->
      <div class="main-wrap">

          <!-- class : comm-story-wrap (S) -->
          <div class="comm-story-wrap">
            <div class="inner-base-box">
              <div class="tit-box">
                <div class="tit"><strong>정보/소통</strong>에서 <br>  회원님들과 이야기를 <br> 나눠보세요!</div>
                <div class="btn-more"><router-link to="/board/569?upper=431&menu=491" class="dg-fnt-mont">VIEW MORE</router-link></div>
              </div>
              <ul>
                <li v-for="article in articles" :key="article.articleId">
                  <router-link :to="`/board/569?upper=431&menu=491&c=detail&at=${article.articleId}&fr=main`" class="">
                    <div class="txt1 ellipsis" style="width: 200px">{{ article.title }}</div>
                    <div class="txt2" v-html="article.contents" v-if="!article.mobile"></div>
                    <div class="txt2" v-html="article.contents.replace(/(?:\r\n|\r|\n)/g, '<br />')" v-else></div>
                    <div class="date-box"><span class="">{{ article.writer }}</span><em class="dg-fnt-mont">{{ formatDate(article.createdAt) }}</em></div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <!-- class : comm-story-wrap (E) -->
          <!-- class : comm-event-wrap (S) -->
          <div class="comm-event-wrap">
            <div class="inner-base-box">
              <div class="tit-box">
                <div class="tit">행사게시판</div>
                <div class="txt">전자사랑모임의 모든 행사 및 경조사를<br> 한 눈에 확인할 수 있습니다.</div>
                <div class="btn-more"><router-link to="/group/schedule?upper=431&menu=488" class="dg-fnt-mont">VIEW MORE</router-link></div>
              </div>
              <div class="event-slide">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <ul>
                      <li @click="goToScheduleDetail(s)" v-for="s in schedules" :key="s.meetingId" style="cursor: pointer">
                        <div class="date-box"><strong>{{ s.startDt | moment('MM.DD') }}</strong><em v-if="$moment().format('YYYY-MM-DD') === s.startDt">TODAY</em></div>
                        <div class="text-box">
                          <span class="st-cate st1">{{ s.group.parent ? s.group.parent.groupName : s.group.groupName }}</span>
                          <div class="txt1"><span class="ellipsis" style="width: 300px">{{ s.title }}</span> <em class="ellipsis" style="width: 300px"> {{ s.strippedContent }}  </em></div>
                          <div class="map">{{ s.place }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Add Navigation -->
                <div class="swiper-button-prev" @click="prevSchedule"></div>
                <div class="swiper-button-next" @click="nextSchedule"></div>
              </div>
            </div>
          </div>
          <!-- class : comm-event-wrap (E) -->

          <!-- class : comm-photo-wrap (S) -->
          <div class="comm-photo-wrap">
            <div class="inner-base-box">
              <div class="tit-box">
                <div class="tit">포토게시판</div>
                <div class="btn-more"><router-link to="/board/568?upper=431&menu=490" class="dg-fnt-mont">VIEW MORE</router-link></div>
              </div>


              <div class="photo-slide">
                <slider ref="slider2" class="photo-slide-inner"  :options="options2" @slide="onSlide">
                  <slideritem v-for="(photo,i) in photos" style="margin-right: 30px" class="swiper-slide" :key="`Img-${i}`">

                    <div class="swiper-wrapper">
                    <div class="img-box">
                      <v-img :src="photo.mainImage.fileServerName ? `/attachment/board/${photo.boardId}/${photo.mainImage.thumbnail}` : '/photo_default.jpg'"
                             v-on:error.once="photo.mainImage.thumbnail='/photo_default.jpg'"/>
                    </div>
                    <div class="txt-box">
                      <div class="txt1 ellipsis">{{ photo.title }}</div>
                      <div class="txt2 ellipsis2">{{ getContents(photo.contents) }}</div>
                      <router-link :to="`/board/568?upper=431&menu=490&c=detail&at=${photo.articleId}&fr=main`" class="">자세히보기</router-link>
                    </div>
                    </div>
                  </slideritem>
                </slider>
              <!-- Add Navigation -->
              <div class="swiper-button-prev" @click="onClickCurrentPage(true,'2')"></div>
              <div class="swiper-button-next" @click="onClickCurrentPage(false,'2')"></div>
              </div>
            </div>
          </div>
          <!-- class : comm-photo-wrap (E) -->

        </div>
          <!-- class : main-wrap (E) -->
    </section>
  </div>
</template>

<script>
import menuApi from "@/api/MenuApi";
import articleApi from "@/api/ArticleApi";
import moment from "moment";
import {slider, slideritem} from "vue-concise-slider";
import meetingApi from "@/api/MeetingApi";

export default {
  name: "CommunityMain",
  components:{
    slider,
    slideritem,
  },
  data(){
    return {
      rowIndex:-1,
      options2:{
        pagination: false,
        loopedSlides: 3,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: true, // Scratch style
        speed: 300, // Sliding speed
        loop:true,
      },

      tabIndex:0,
      tabs:[],
      currentTabMenu:[],
      boardId:'',
      boardName:'',
      menuName:'',

      articles:[],
      param:{
        size:4
      },
      photos:[],
      schedules:[],
      scheduleParams:{
        page:1,
        size:6
      },
      scheduleTotalItem:null,
    }
  },
  created() {
    //this.currentTab = this.$route.params.tapNo
    this.parentMenu = this.$route.query.upper

    menuApi.getMenu(this.parentMenu).then(data=>{
      this.menuName=data.menuName
      this.tabs = data.childMenus;    //ce분과, it분과, 디스플레이분과 etc.
      this.currentTabMenu = this.tabs[this.tabIndex]
      this.boardId = this.currentTabMenu.boardId
      //this.boardName = this.currentTabMenu.childMenus[0].menuName
      //this.getGroupInfo();
    })

    articleApi.list(569, this.param).then(data => {
      this.articles = data.content;
    });
    articleApi.list(568, this.param).then(data => {
      this.photos = data.content;
    });

    this.getSchedule()

  },
  methods:{
    prevSchedule() {
      if(this.scheduleParams.page > 1) {
        this.scheduleParams.page = this.scheduleParams.page - 1;
        this.getSchedule();
      } else {
        alert("이전 행사 일정이 없습니다.")
      }
    },
    nextSchedule() {
      if(this.scheduleParams.page < Math.ceil(this.scheduleTotalItem/this.scheduleParams.size)) {
        this.scheduleParams.page = this.scheduleParams.page + 1;
        this.getSchedule();
      } else {
        alert("다음 행사 일정이 없습니다.")
      }

    },
    getSchedule() {
      meetingApi.list(this.scheduleParams).then(data => {

        this.scheduleTotalItem = data.totalElements;
        this.schedules = data.content.map(m => {
          return Object.assign(m, {
            name: m.title,
            start: moment(`${m.startDt} ${m?.startTm?? '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toDate(),// ? m.startDt + (m.startTm ? ' ' + m.startTm.substr(0, 5) : '') : null,
            end: moment(`${m.endDt} ${m?.endTm?? '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').toDate(), // ? m.endDt + (m.endTm ? ' ' + m.endTm.substr(0, 5) : '') : null,
            timed: m.startTm && m.endTm,
            strippedContent: m.meetingContents?.replace(/<\/?[^>]+>/ig, "")??''
          });
        }).sort((a,b) => {
          return b.start - a.start
        });//.filter(m => m.start !== null && m.end !== null);

        // console.log(this.schedules);

      });
    },
    goToScheduleDetail(s) {
      //this.$router.push({name:'schedule', params: { id: s.meetingId}, query: {menu:this.parentMenu}})
      this.$router.push(`/group/schedule?articleId=${s.meetingId}&upper=${this.parentMenu}&menu=488`);
    },
    onSlide(){
      this.rowIndex = -1
    },
    onClickCurrentPage(e,slideType){
      this.rowIndex = -1
      let move = e ? 'slidePre' : 'slideNext'
      if(slideType === '2'){
        this.$refs.slider2.$emit(move)
      }else if(slideType === '3'){
        this.$refs.slider3.$emit(move)
      }else{
        this.$refs.slider.$emit(move)
      }
    },
    onClickTab(tab){
      let url = tab.url;
      if(tab.menuType === 'TYPE_BOARD') {
        url = `/board/${tab.boardId}`;
      }
      this.$router.push(url+`?upper=${this.parentMenu}&menu=${tab.menuId}`).catch(()=>{});
    },
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    getContents(val){
      return val.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "");
    },
    /*탭 오픈*/
    openTab(){
      this.$store.commit('setTapOpen')
    }
  }
}
</script>

<style scoped>

</style>
