import axios from 'axios';
import {clarify} from "../utils/clear";

class MeetingApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/group/meeting`, {params}).then(response=>response.data);
    }

    get(meetingId) {
        return axios.get(`${this.endpoint}/group/meeting/${meetingId}`, {}).then(response=>response.data);
    }

    save(meeting) {
        return axios.post(`${this.endpoint}/group/meeting/save`, meeting).then(response=>response.data);
    }

    delete(meetingId) {
        return axios.delete(`${this.endpoint}/group/meeting/delete/${meetingId}`, {}).then(response=>response.data);
    }

    uploadImage(f) {
        const formData = new FormData();
        formData.append('file', f);
        return axios.post(`${this.endpoint}/group/meeting/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }
    uploadExcel(f) {
        const formData = new FormData();
        formData.append('file', f);
        return axios.post(`${this.endpoint}/group/meeting/upload/excel`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

}


export default new MeetingApi();
